import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Menu, Avatar, Modal, Button } from "antd";
import { logout, fontOverflow, getLocale } from "src/libs/utils";
import IconGasUser from "src/component/IconGasUser";
import { GlobalContext } from "src/view/reducer";
import Watermark from "src/component/WaterMark";
// import LangMenu from "./component/LangMenu";
import Brand from "./component/Brand";
import "src/scss/common.scss";
import "src/scss/main.scss";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const MainLayout = (props) => {
  const history = useHistory();

  const { children } = props;

  // 当前租户
  const { user, customer } = useContext(
    GlobalContext
  );
  const [realname, setRealname] = useState(lang.昵称);

  // 退出确认
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  // 退出 确认
  const handleOk = () => {
    setIsConfirmVisible(false);
    logout();
  };

  // 退出 取消
  const handleCancel = () => {
    setIsConfirmVisible(false);
  };

  // 我的 event
  const handleHeaderMenuClick = ({ key, domEvent }) => {
    // debugger;
    switch (key) {
      // 退出
      case "sub_user-signOut":
        setIsConfirmVisible(true);
        break;

      default:
        break;
    }
  };

  useEffect(
    () => {
      if (user) setRealname(user?.displayName);
    },
    [user]
  );

  return (
    <Layout className="main-container">
      <Watermark watermarkText={user.username || user.displayName || user.email} />
      <Header className="header-blue">
        <Brand />
        <Menu
          theme="dark"
          mode="horizontal"
          className="menu-blue menu-right"
          selectable={false}
          onClick={handleHeaderMenuClick}
        >
          {/* <LangMenu /> */}
          <SubMenu
            key="sub_user"
            title={
              fontOverflow(realname, 15) || fontOverflow(user.username, 15)
            }
            icon={
              <Avatar
                src={<IconGasUser user={user} />}
                style={{ backgroundColor: "#F7F7F7" }}
              />
            }
          >
            <Menu.Item key="sub_user-signOut">{lang.退出}</Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      <Layout>
        <Layout style={{ padding: "0" }}>
          <Content
            className="site-layout-background"
            style={{
              margin: "0",
              height: "100%",
              overflow: "auto",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>

      <Modal
        title={lang.退出确认}
        okText={lang.确定}
        cancelText={lang.取消}
        open={isConfirmVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <p>{lang.确定要退出吗}？</p>
      </Modal>
    </Layout>
  );
};

export default MainLayout;
