/**
 * 根据环境、通道和区域格式化API的URL。
 *
 * @param {Object} options - 包含环境、通道和区域的选项对象。
 * @param {string} options.env - 环境名称，如 'prod'、'staging' 等。
 * @param {string} [options.lane] - 通道名称，可选。domain-lane
 * @param {string} [options.region] - 区域名称，可选。region 存在时，替换env，（service.eu.gs-robot.com）
 * @returns {Object} - 包含格式化后的API URL的对象。组装规则为: https://domain-lane.(region || env).gs-robot.com
 */
export function generateConfig() {
  const { REACT_APP_LANE: lane, REACT_APP_REGION: region, REACT_APP_NODE_ENV: env } = process.env;
  console.log('REACT_APP_LANE', lane, typeof region, env)
  // 如果lane存在且不为'undefined'，则在域名中添加'-lane'，否则为空字符串
  const laneValue = lane && lane !== 'undefined' ? '-' + lane : '';
  // 如果region存在且不为'undefined'，则在域名中添加'.region'，否则为空字符串
  const regionValue = region && region !== 'undefined' ? '.' + region : '';
  // 如果env为'prod'，则在域名中不添加'.env'，否则添加'.env'
  const envValue = env === 'prod' ? '' : '.' + env;
  // 拼接laneValue、regionValue或envValue
  const concatValue = `${laneValue}${regionValue || envValue}`;
  // 打印拼接后的值和各个参数的值以及region的类型
  // 返回包含格式化后的API URL的对象
  return {
    // 格式化后的bot URL
    bot: `https://bot${concatValue}.gs-robot.com`,
    // 格式化后的escalade URL
    escalade: `https://escalade${concatValue}.gs-robot.com`,
    // 格式化后的home URL
    homeUrl: `https://home${concatValue}.gs-robot.com`,
    openapi: `https://openapi${regionValue || envValue}.gs-robot.com`,
    developerUrl: `https://developer${regionValue || envValue}.gs-robot.com`,
    // 格式化后的service URL
    serviceUrl: `https://service${concatValue}.gs-robot.com`,
    // 格式化后的signin URL
    signinUrl: `https://signin${concatValue}.gs-robot.com`,
    apiGetway: `bot${concatValue}.gs-robot.com`
  };
}

/**
 * context 新旧接口数据结构转换
 */
export function convertContext(context) {
  const { person = {}, customer = {}, roles = [], loginLocation = {} } = context;
  return {
    person: {
      id: person.personId,
      username: person.username,
      displayName: person.displayName,
      email: person.email,
      contactEmail: person.contactEmail,
      locale: person.locale,
      privacyRegion: person.privacyRegion,
      phoneNumber: person.phoneNumber,
      measureType: person.measureType
    },
    customer: {
      id: customer.customerId,
      name: `customers/${customer.customerId}`,
      displayName: customer.customerName,
      isGroupCompany: customer.isGroupCompany,
      tenantId: customer.tenantId,
      isInternal: customer.isInternal,
      tenant: {
        id: customer.tenantId,
        name: `tenants/${customer.tenantId}`,
        isManaged: customer.isManaged,
        displayName: customer.displayName
      },
      isManualApproval: customer.isManualApproval
    },
    roles: roles?.map(role => ({
      id: role?.roleId,
      code: role?.alias,
      title: role?.displayName
    })),
    login: {
      ipAddress: loginLocation.ipAddress,
      city: loginLocation.city,
      country: loginLocation.country,
    },
    internalPerson: customer.isInternal,
    tenant: {
      id: customer.tenantId,
      name: `tenants/${customer.tenantId}`,
      displayName: customer.customerName
    }
  };
}


