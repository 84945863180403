/**
 * 系统主页
 */
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { GlobalContext } from "src/view/reducer";
import MainLayout from "src/layout/MainLayout";
import { mainRoutes } from "src/routes";
import { useUpdateMe } from "src/hook/useUpdate";
import { getToken } from "src/libs/utils";

const Main = () => {
  const [updateMe, setUpdateMe] = useUpdateMe(false);
  const token = getToken();

  useEffect(
    () => {
      // 当token存在时，token发生变化，则重新获取用户context；主要针对相同浏览器登录不同帐号时；
      if (token) setUpdateMe(true);
    },
    [token]
  );

  return (
    <>
      <MainLayout>
        <Switch>
          {mainRoutes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                render={(props) => {
                  return <route.component {...props} />;
                }}
              />
            );
          })}
          <Redirect to="/app/center/home" />
        </Switch>
      </MainLayout>
    </>
  );
};
export default Main;
