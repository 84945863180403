// 异步加载组件
import { asyncComponent } from 'react-async-component';

const APPHome = asyncComponent({resolve: () => import("./APPHome")});

const routers = [
  {
    path: '/app/center/home',
    component: APPHome
  },
];

export default routers;