import Center from "../view/app-center/_router";
import PageErr from "../view/PageErr";

export const commonRoutes = [
  {
    path: "/404",
    component: PageErr,
  },
];

export const mainRoutes = [
  ...Center,
];
