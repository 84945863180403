import { Roles } from 'src/api/data';

interface IProps {
  roles?: Roles[];
}

/**
 * useUpdateMe 中会调用此方法，将access更新到context
*/
export const access = (props: IProps) => {
  const { roles } = props;

  let state = {
    // IAM后台管理
    canConsole: false,
  }

  roles && roles.forEach(role => {
    const { code } = role;

    switch (code) {
      // 超管：IAM后台管理，访问管控，客户管理
      case 'SUPER_ADMINISTRATOR':
        {
          // 超管 所有权限。
          state = new Proxy(state, {
            get: () => true
          });
        }
        break;

      default:
        break;
    }
  });

  return state;
}
