import envAPI, { PROXY_ENV, regionValue, REGION } from "../api/config";
import { getLocale, transLocaleForCloud } from "./utils";
import moment from "moment";
import cleanImage from "../../assets/images/cleanLibrary.png"
import hwordGroupImage from "../../assets/images/hwordGroup.svg"

import factoryManagement from "../../assets/images/factoryManagement.svg"
import beep from "../../assets/images/beep.svg";
import reportCentre from "../../assets/images/reportCentre.svg"
import operationMaintenance from "../../assets/images/operationMaintenance.svg"
import parameterFlowManagement from "../../assets/images/parameterFlowManagement.svg"
import otaManagement from "../../assets/images/otaManagement.svg"
import { generateConfig } from "src/gs-utils/core/utils";

/**
 * @url 跳转携带locale到cloud
 * ?locale=zh-CN，ko，pl，ja-JP，en-US
 **/
const cloud_locale = transLocaleForCloud(getLocale());

/**
 * copyright
 * 2019-2020
 **/
const { serviceUrl, homeUrl, developerUrl  } = generateConfig();

/**
 * @应用配置
 * console IAM后台管理
 * gsCloudUrl 机器人平台
 * robotbi quickbi数据中心
 * biManage 数据中心管理
 * iot 物联网平台
 * openAPI 开发者中心
 **/
const SERVICES = {
  // 权限系统【超级管理员】
  console: {
    key: "console",
    param: "console",
    title: "IAM后台管理",
    image: `${envAPI.assets}/app-console.svg`,
    menuTitle: "IAM后台管理",
    description: "租户管理",
    descLocaleKey: "console描述",
    href: `${homeUrl}/tenant-center#/gas/tenant/manage`,
  },
  // 权限系统【租户管理员】
  iam: {
    key: "iam",
    param: "iam",
    title: "访问管控",
    image: `${envAPI.assets}/app-iam.svg`,
    menuTitle: "访问管控",
    description: "管控各应用的权限分配",
    descLocaleKey: "iam描述",
    href: `${serviceUrl}/iam?locale=${cloud_locale}`,
  },
  // 云平台
  gsCloud: {
    key: "gsCloud",
    param: "gsCloud",
    title: "机器人平台",
    image: `${envAPI.assets}/app-task.svg`,
    menuTitle: "机器人平台",
    description: "机器人业务管理",
    descLocaleKey: "cloud描述",
    href: `${serviceUrl}?locale=${cloud_locale}#/`,
  },
  // 报表系统
  report: {
    key: "report",
    param: "report",
    title: "数据中心",
    image: `${envAPI.assets}/app-report.svg`,
    menuTitle: "数据中心",
    description: "从数据图表中获取信息",
    descLocaleKey: "report描述",
    href: `${serviceUrl}/data-center?locale=${cloud_locale}`,
  },
  // 物联网平台
  iot: {
    key: "iot",
    param: "iot",
    title: "物联网平台",
    image: `${envAPI.assets}/app-iot.svg`,
    menuTitle: "物联网平台",
    description: "物联网平台",
    descLocaleKey: "iot描述",
    href: `${homeUrl}/iot`,
  },
  // 数据中心管理
  biManage: {
    key: "biManage",
    param: "biManage",
    title: "数据中心管理",
    image: `${envAPI.assets}/app-biCenter.svg`,
    menuTitle: "数据中心管理",
    description: "数据中心后台配置",
    descLocaleKey: "biManage描述",
    href: `${homeUrl}/partner-report`,
  },
  cleanLibrary: {
    key: "cleanLibrary",
    param: "cleanLibrary",
    title: "标准工作项库",
    image: cleanImage,
    menuTitle: "标准工作项库",
    description: "标准工作项库管理",
    descLocaleKey: "cleanLibrary描述",
    href: `${homeUrl}/clean-library`,
  },
  hwordGroup: {
    key: "hwordGroup",
    param: "hwordGroup",
    title: "华住注册",
    image: hwordGroupImage,
    menuTitle: "华住注册",
    description: "华住注册",
    descLocaleKey: "hwordGroup描述",
    href: `${homeUrl}/hword-group`,
  },
  factoryManagement: {
    key: "factoryManagement",
    param: "factoryManagement",
    title: "工厂信息管理",
    image: factoryManagement,
    menuTitle: "工厂信息管理",
    description: "工厂信息管理",
    descLocaleKey: "工厂信息管理",
    href: `${homeUrl}/factory-management`,
  },
  beep: {
    key: "beep",
    param: "beep",
    title: "基础数据管理",
    image: beep,
    menuTitle: "基础数据管理",
    description: "基础数据管理",
    descLocaleKey: "基础数据管理",
    href: `${homeUrl}/beep`,
  },
  reportCentre: {
    key: "reportCentre",
    param: "reportCentre",
    title: "报表中心",
    image: reportCentre,
    menuTitle: "报表中心",
    description: "报表中心",
    descLocaleKey: "报表中心",
    href: `${homeUrl}/report-centre`,
  },
  equipmentImport: {
    key: "equipmentImport",
    param: "equipmentImport",
    title: "设备导入管理",
    image: cleanImage,
    menuTitle: "设备导入管理",
    description: "设备导入管理",
    descLocaleKey: "设备导入管理",
    href: `${homeUrl}/equipment-import`,
  },
  // 运维运营
  operationMaintenance: {
    key: "operationMaintenance",
    param: "operationMaintenance",
    title: "运维运营",
    image: operationMaintenance,
    menuTitle: "运维运营",
    description: "运维运营",
    descLocaleKey: "运维运营",
    href: `${homeUrl}/operation-maintenance`,
  },
  parameterFlowManagement: {
    key: "parameterFlowManagement",
    param: "parameterFlowManagement",
    title: "机器分析与管控",
    image: parameterFlowManagement,
    menuTitle: "机器分析与管控",
    description: "机器分析与管控",
    descLocaleKey: "机器分析与管控",
    href: `${homeUrl}/parameter-flow-management`,
  },
  otaManagement: {
    key: "otaManagement",
    param: "otaManagement",
    title: "升级管理",
    image: otaManagement,
    menuTitle: "升级管理",
    description: "升级管理",
    descLocaleKey: "升级管理",
    href: `${homeUrl}/ota-management`,
  },
};


export {
  SERVICES,
  developerUrl
};
