import React, { createContext, useReducer, useState } from "react";

const initState = {
  lang: {},
  user: {},                           // 当前登录用户的信息
  tenant: {},                         // 当前登录用户的所属租户信息
  roles: [],                          // 当前登录用的角色列表
  applicationPermissions: [], // 当前登录用户的应用权限
  customer: {},                       // 当前客户信息
  internalPerson: false,              // 是否是内部用户
}

// 全局Context
export const GlobalContext = createContext(null);

// reducer
const reducer = (state, action) => {
  console.log("action ==== ", action);
  switch (action.type) {
    // 更新当前用户默认语言
    case 'UPDATE_USER_LOCALE':
      return { ...state, userLocale: action.data };

    // 更新当前登录 user 信息
    case 'UPDATE_CURRENT_USER':
      return { ...state, user: action.data };

    // 更新当前登录 tenant 信息
    case 'UPDATE_CURRENT_TENANT':
      return { ...state, tenant: action.data };

    // 更新当前登录 roles 信息
    case 'UPDATE_CURRENT_ROLES':
      return { ...state, roles: action.data };

    // 更新当前登录 应用权限信息
    case 'UPDATE_APPLICATION_PERMISSIONS':
      return {...state, applicationPermissions: action.data };
      
    更新当前客户信息
    case 'UPDATE_CUSTOMER':
      return {...state, customer: action.data };
      
    // 更新是否是内部用户
    case 'UPDATE_INTERNAL_PERSON':
      return {...state, internalPerson: action.data };

    default:
      return state; // 添加默认返回原始状态，避免返回 undefined
  }
};

export const Common = props => {
  // user={user} tenant={tenant} roles={roles}
  const [state, dispatch] = useReducer(reducer, { ...initState });

  return (
    <GlobalContext.Provider value={{
      ...state,
      dispatch
    }}>
      {props.children}
    </GlobalContext.Provider>
  );
};