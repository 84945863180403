import React, { useState, useEffect, useContext, useCallback } from "react";
import { GlobalContext } from "src/view/reducer";
import { Login } from "src/api/Auths";
import { access } from "src/access";
import { transLocale, clearAccess } from "src/libs/utils";
import { initI18n } from "src/i18n/index";
import { personApi } from "src/api/Auths";
import { SERVICES } from "src/libs/configData";
import { convertContext } from "src/gs-utils/core/utils";

export const useUpdateMe = (state) => {
  const loginApi = new Login();
  const { dispatch } = useContext(GlobalContext);
  const [updateRes, setUpdateRes] = useState(state);

  // 获取应用权限
  const getApplicationPermissions = async (user) => {
    const res = await personApi.associatedApplications({
      personId: user?.id,
    });
    dispatch({ type: "UPDATE_APPLICATION_PERMISSIONS", data: res })
  }

  // 获取用户信息
  const getUserContext = useCallback(
    async () => {
      if (updateRes) {
        try {
          await loginApi.getMe().then((res) => {
            const { code, data } = res;
            const { tenant, person, roles, error, customer, internalPerson } = convertContext(data);
            if (!internalPerson) {
			  clearAccess();
              console.log('SERVICES.gsCloudUrl.href', SERVICES.gsCloud.href)
              window.location.href = SERVICES.gsCloud.href;
			  return;
            };
            if (!code && tenant && person) {
              dispatch({ type: "UPDATE_CURRENT_USER", data: person });
              dispatch({ type: "UPDATE_CURRENT_TENANT", data: tenant });
              dispatch({ type: "UPDATE_CURRENT_ROLES", data: roles });
              dispatch({ type: "UPDATE_CUSTOMER", data: customer });
              dispatch({ type: "UPDATE_INTERNAL_PERSON", data: internalPerson });
              // 保存用户自己的语言
              const { locale } = person;
              if (locale) {
                const _locale = transLocale(locale);
                dispatch({ type: "UPDATE_USER_LOCALE", data: _locale });
              }

              getApplicationPermissions(person);

              // 本地不存在语言
              const _locale = $storage.get("locale");
              if (!_locale) {
                $storage.set("locale", transLocale(locale));
                // 语言包初始化之后，需要reload一下，否则header 组件拿不到最新的locale
                initI18n().then((_) => {
                  window.location.reload();
                });
              }
            }
            setUpdateRes(false);
          });
        } catch (error) {
          setUpdateRes(false);
        }
      } else {
        return;
      }

      // 获取当前用户信息
    },
    [dispatch, loginApi.getMe, updateRes, setUpdateRes]
  );

  useEffect(
    () => {
      if (updateRes) {
        // 更新用户信息
        getUserContext();
      }
    },
    [updateRes]
  );

  return [updateRes, setUpdateRes];
};
