import React, { useEffect, useState } from "react";
import { createRoot } from 'react-dom/client';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { RecoilRoot } from "recoil";
import { Spin, ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale/zh_CN';
import en_US from 'antd/lib/locale/en_US';
import ja_JP from 'antd/lib/locale/ja_JP'
import 'antd/dist/antd.css';

import storage from './libs/storage'; // 全局配置 storage
import { commonRoutes } from "./routes";
import Main from "./view/main/Main";
import PageErr from "./view/PageErr";
import { initI18n } from "./i18n/index";
import { Common } from "./view/reducer";
import {
  getToken,
  ssoLogin,
  getTokenBySSO,
  getUrlParams,
  removeUrlParam,
  getLocale,
  transUrlLocale
} from "./libs/utils";
import envAPI from "src/api/config";

window['$storage'] = storage;
window['$session'] = storage;

(() => {
  // 内部应用只有中文，并设置home域缓存均为中文
  localStorage.setItem('locale', 'zh-CN');

})()

const App = props => {
  const [antdLoacle, setAntdLoacle] = useState(zh_CN);

  return (
    <RecoilRoot>
      <ConfigProvider locale={antdLoacle}>
        <Common>
          <Router>
            <Switch>
              {/* 主页面 使用 view/main =》 Main 组件渲染 */}
              {/* <Route path="/" exact={true} render={() => <Main><APPHome/></Main>} /> */}
              <Route path="/app" render={props => {
                const token = getToken();
                // 路由拦截，无token 则去sso/login获取token
                if (!token) {
                  ssoLogin();
                } else return <Main {...props} />;
              }} />
              {
                commonRoutes.map(route => {
                  return <Route key={route.path} {...route} />
                })
              }
              <Redirect to="/app/center/home" />
            </Switch>
          </Router>
        </Common>
      </ConfigProvider>
    </RecoilRoot>
  )
};

/**
 * addEventListener 解决重复创建root的问题
**/
let container = null;
document.addEventListener('DOMContentLoaded', function (event) {
  if (!container) {
    container = document.getElementById("app")
    // createRoot(container!) if you use TypeScript
    const root = createRoot(container);
    // 先加载语言包
    root.render(
      <div className="loading-container">
        <Spin />
        <p style={{ color: "#aaa", margin: "1rem auto" }}>Loading...</p>
      </div>
    );

    try {
      getTokenBySSO().then(res => {
        initI18n().then(res => {
          root.render(<App />);
        });
      }).catch(error => {
        root.render(<Main><PageErr /></Main>);
      });
    } catch (error) {
      console.log('catch index error', error);
    }
  }
})

